import React from 'react'

export interface IconProps {
  size?: number
}

// export type MyIconType = (props: IconProps) => JSX.Element

const ResumeIcon: React.FC<IconProps> = ({ size }) => (
	<>
		<svg
			version='1.0'
			xmlns='http://www.w3.org/2000/svg'
			width={size}
			height={size}
			viewBox='0 0 200.000000 200.000000'
			preserveAspectRatio='xMidYMid meet'
		>
			<g
				transform='translate(0.000000,200.000000) scale(0.100000,-0.100000)'
				fill='currentColor'
				stroke='none'
			>
				<path
					d='M257 1932 c-16 -17 -17 -96 -17 -934 0 -874 1 -916 18 -931 17 -16
      83 -17 744 -17 690 0 726 1 741 18 16 17 17 79 17 693 l0 674 -258 257 -257
      258 -486 0 c-458 0 -487 -1 -502 -18z m863 -348 l0 -216 29 -29 29 -29 216 0
      216 0 0 -555 0 -555 -610 0 -610 0 0 800 0 800 365 0 365 0 0 -216z m370 -111
      c0 -2 -47 -3 -105 -3 l-105 0 0 107 0 108 105 -105 c58 -58 105 -106 105 -107z'
				/>
				<path
					d='M752 1600 c-44 -27 -72 -76 -72 -128 0 -52 19 -91 60 -126 26 -21 42
      -26 86 -26 103 0 154 51 154 152 0 46 -5 62 -26 88 -15 17 -39 37 -55 45 -40
      21 -108 19 -147 -5z'
				/>
				<path
					d='M667 1266 c-35 -32 -53 -72 -62 -133 -13 -99 -26 -94 228 -91 l222 3
      -2 69 c-3 77 -33 142 -77 165 -21 12 -31 11 -66 -3 -50 -20 -110 -21 -157 -1
      -47 20 -56 19 -86 -9z'
				/>
				<path
					d='M610 850 c-24 -24 -26 -77 -4 -108 l15 -22 375 0 c361 0 375 1 394
      20 12 12 20 33 20 55 0 22 -8 43 -20 55 -19 19 -33 20 -390 20 -357 0 -371 -1
      -390 -20z'
				/>
				<path
					d='M606 528 c-22 -31 -20 -84 4 -108 19 -19 33 -20 390 -20 357 0 371 1
      390 20 24 24 26 77 4 108 l-15 22 -379 0 -379 0 -15 -22z'
				/>
			</g>
		</svg>
	</>
)

export default ResumeIcon
